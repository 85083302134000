import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { AppState } from '../../reducers'

import {
  Blanket,
  BlanketTitle,
  BlanketBackground,
  BlanketContent,
} from '../../components/Blanket'
import { Button } from '../../components/Button'
import { SEO } from '../../components/Seo'
import { Table } from 'react-bootstrap'
import { TeamLogo } from '../../components/TeamLogo'
import { Spinner } from '../../components/Spinner'

import { fetchLeaderBoard } from '../../actions/leaderboard'
import { getLeaderBoard } from '../../selectors/leaderboard'

import * as classes from './classifica.module.scss'

interface Props {
  fetchLeaderBoard: typeof fetchLeaderBoard
  leaderBoard: ReturnType<typeof getLeaderBoard>
}

function getTotals(pointsMade: number, pointsReceived: number) {
  if (pointsReceived === 0) {
    return 0
  }

  return (pointsMade / pointsReceived).toFixed(3)
}

class LeaderBoard extends React.Component<Props> {
  componentDidMount(): void {
    this.props.fetchLeaderBoard()
  }

  render() {
    const { leaderBoard } = this.props

    return (
      <Blanket>
        <SEO title={'Classifica'} />

        <BlanketBackground>
          <BlanketTitle>Classifica</BlanketTitle>

          <BlanketContent>
            <Table className={classes.table} responsive>
              <thead>
                <tr>
                  <th>P.</th>
                  <th>Squadra</th>
                  <th>Punti</th>
                  <th>Partite</th>
                  <th>Punti</th>
                  <th>Quoz. Punti</th>
                </tr>
              </thead>

              <tbody>
                {leaderBoard.data.map((team) => (
                  <tr
                    className={classNames(
                      team.teamId === process.env.REACT_APP_RE_TEAM_ID &&
                        classes.reTeam
                    )}
                    key={team.teamId}
                  >
                    <td>{team.standing}</td>
                    <td>
                      <TeamLogo
                        teamId={team.teamId}
                        teamLabel={team.teamName}
                        className={classes.teamLogo}
                      />

                      {team.teamName}
                    </td>
                    <td>{team.points}</td>
                    <td>
                      <span className={classes.redLetter}>V</span> {team.win}{' '}
                      <span className={classes.redLetter}>P</span> {team.loss}
                    </td>
                    <td>
                      <span className={classes.redLetter}>F</span>{' '}
                      {team.pointsMade}{' '}
                      <span className={classes.redLetter}>S</span>{' '}
                      {team.pointsReceive}
                    </td>
                    <td className={classes.quotient}>
                      {getTotals(
                        parseInt(team.pointsMade),
                        parseInt(team.pointsReceive)
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <Spinner
              show={leaderBoard.fetching && leaderBoard.data.length === 0}
            />

            <div className={classes.buttonContainer}>
              <Button
                as={'a'}
                href={'https://www.legabasket.it/lba/6/calendario/standings'}
                target={'_blank'}
                rel={'noreferrer noopener'}
              >
                Classifica Completa
              </Button>
            </div>
          </BlanketContent>
        </BlanketBackground>
      </Blanket>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  leaderBoard: getLeaderBoard(state),
})

export default connect(mapStateToProps, { fetchLeaderBoard })(LeaderBoard)
